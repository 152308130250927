<template>
    
  <v-container fluid style="overflow-y:hidden;">

      <!-- Section Path File/Modul -->
      <v-breadcrumbs 
          :items="pathModul"
          divider="-"
          normal
          class="pathModul"
      ></v-breadcrumbs>

      <!-- Section Filtering Input/Select/Button -->
      <v-container fluid >

        <v-row>
          <v-col :cols="$vuetify.breakpoint.lgAndUp? '2' : '12'" :class="$vuetify.breakpoint.lgAndUp? 'pr-6 pl-6' : ''">
            Year :
            <v-select
            class="standard_input"
            :items=listYear
            outlined
            dense
            v-model="modelYear"
            solo
            @change="selectYear()"
            ></v-select>

            Case ID :
            <v-select
            class="standard_input"
            :items=listSelectedCaseID
            outlined
            solo
            dense
            :disabled="modelYear == ''"
            v-model="modelCaseid"
            @change="generateTableReport()"
            ></v-select>

          </v-col>
        
          <v-divider vertical style="height: calc(100vh - 160px) !important" v-if="$vuetify.breakpoint.lgAndUp"></v-divider>

          <v-col :class="$vuetify.breakpoint.lgAndUp? 'px-10' : 'px-3'">
            
            <v-card>

              <!--<v-card-title class="black--text">Investigate Study Report</v-card-title>-->
              <v-card-title class="px-6 card-header white--text justify-left">
                <v-icon class="mr-3 white--text">mdi-text-box-multiple</v-icon> <h3>Investigate Study Report</h3>
              </v-card-title>

              <v-container fluid class="pa-6" style="height: calc(100vh - 230px);overflow-y:auto;">
                <v-simple-table class="standard_table cust-is-simple-table">
                  <thead>
                    <tr>
                      <th
                      v-for="(item,i) in thead_isreport"
                      :key="i"
                      class="text-center"
                      >
                        {{item.text}}
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="loadingTable == true">
                    <tr>
                      <td colspan="8">
                        <v-progress-linear
                        indeterminate
                        color="primary"
                        ></v-progress-linear>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>

                    <tr v-if="tbody_isreport.length == 0">
                      <td colspan="9" style="text-align:center">No data available</td>
                    </tr>

                    <tr
                    v-else
                    v-for="(item,i) in tbody_isreport"
                    :key="i"
                    >

                        <td :rowspan="tbody_isreport.length" class="text-center" :style="i != 0? 'display:none;' : ''">
                            {{ item.state }}
                        </td>

                        <td :rowspan="tbody_isreport.length" class="text-center" :style="i != 0? 'display:none;' : ''">

                            <v-btn text color="primary" style="text-decoration: underline;" >
                              {{ item.reportid }}
                            </v-btn> <br>

                            <v-btn :disabled="item.executivesummary == '' || item.executivesummary == null" block class="my-4 button" color="primary" @click="openAttachFile('executivesummary')">
                              Executive Summary
                            </v-btn> <br>

                            <v-btn :disabled="item.fullreport == '' || item.fullreport == null" block class="my-4 button" color="primary" @click="openAttachFile('fullreport')">
                              Full Report
                            </v-btn> <br>

                            <v-btn :disabled="item.filesampling == '' || item.filesampling == null" block class="my-4 button" color="primary" @click="openAttachFile('filesampling')">
                              Sampling Data.xls
                            </v-btn> <br> 

                            <v-btn :disabled="item.coa == '' || item.coa == null" block class="my-4 button" color="primary" @click="openAttachFile('coa')">
                              Certificate of Analysis
                            </v-btn> <br>

                            <v-btn :disabled="item.coc == '' || item.coc == null" block class="my-4 button" color="primary" @click="openAttachFile('coc')">
                              Chain of Custody
                            </v-btn> 

                        </td>

                        <td :rowspan="tbody_isreport.length" class="text-center" :style="i != 0? 'display:none;' : ''">
                            {{ item.casetitle }}
                        </td>

                        <td :rowspan="tbody_isreport.length" class="text-center" :style="i != 0? 'display:none;' : ''">
                            <span style="white-space: nowrap;">{{ item.casestartdate_ui }}</span> 
                            <br> to <br>
                            <span style="white-space: nowrap;">{{ item.caseenddate_ui }}</span>
                        </td>

                        <td class="text-center">
                            {{ item.samplingpoint }}
                        </td>

                        <td class="text-center">
                            {{ item.pointriver }}
                        </td>

                        <td class="text-center">
                            {{ item.locationdescription }}
                        </td>

                        <td class="text-center">
                            {{ item.remarks }}
                        </td>

                  </tr>

                  </tbody>
                </v-simple-table>

                
              </v-container>

            </v-card>

          </v-col>
        </v-row>

      </v-container>

  </v-container>

    

</template>


<script>

import axios from 'axios';
import Papa from "papaparse";

export default {

    
    data: () => ({

      pathModul: [
          {
              text: 'Home',
              disabled: false,
              href: '/',
              // href: '/Mapviewer',
          },
          {
              text: 'Report - Investigate Study Report',
              disabled: true,
              href: '/Report/RawData',
          },
      ],

      loadingTable: false,

      modelYear: "",
      listYear: [],
      modelCaseid: "",
      listAllCaseID: [],
      listSelectedCaseID: [],
      GlobalDataBody: [],
      rowspan_samplingpoint: 4,
      thead_isreport: [
        { text: "STATE", value: "state", },
        { text: "CASE ID", value: "reportid", },
        { text: "CASE TITLE", value: "casetitle", },
        { text: "DATE", value: "fromtodate", },
        { text: "SAMPLING POINT", value: "pointinfo", },
        { text: "RIVER", value: "river", },
        { text: "LOCATION DESCRIPTION", value: "locationdesc", },
        { text: "REMARKS", value: "remarks", },
      ],
      tbody_isreport: [],
              
    }),

    mounted() {

      this.getListYears();
      this.getListCaseID();

    },
    
    methods: {

      // General
      convert12Hours(time) {
          var H = +time.substr(0, 2);
          var h = H % 12 || 12;
          var ampm = (H < 12 || H === 24) ? "AM" : "PM";
          time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
          return time;
      },

      convertDateFormat(date){
          var dd = date.split("-")[2];
          var mm = date.split("-")[1];
          var yyyy = date.split("-")[0];
          date = dd + "/" + mm + "/" + yyyy;
          return date;
      },

      getListYears(){

        this.listYear = [];

        axios.get(this.globalUrl+'mqm2/miqims/years', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            
            let data = response.data;

            if(data.length != 0){
              this.listYear = data.sort();
              this.listYear.unshift("All");
            }
            
        })
        .catch(error => {
            console.log(error);
        })

      },

      getListCaseID(){

        this.listAllCaseID = [];

        axios.get(this.globalUrl+'mqm2/miqims/reading_invstudy', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            
            let data = response.data;

            if(data.length != 0){
              for(let i in data){
                this.listAllCaseID.push(data[i].REPORT_ID)
              }
            }
            
        })
        .catch(error => {
            console.log(error);
        })

      },

      selectYear(){

        let listCaseIDbySelectedYear = [];

        for(let i in this.listAllCaseID){
          if(this.modelYear != ""){
            if(this.modelYear != "All"){
              if(this.listAllCaseID[i].includes(this.modelYear)){
                listCaseIDbySelectedYear.push(this.listAllCaseID[i]);
              }
            }
            else{
              listCaseIDbySelectedYear.push(this.listAllCaseID[i]);
            }
          }
        }

        this.listSelectedCaseID = listCaseIDbySelectedYear.sort();
        
      },

      generateTableReport(){

        this.loadingTable = true;

        this.dataSelectedReportCaseID = null;
        this.tbody_isreport = [];

        axios.get(this.globalUrl+'mqm2/miqims/edit_getinfofirst_invstudy?caseid='+this.modelCaseid, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            
            let data = response.data;

            console.log(data.pointinfo);

            for(let i in data.pointinfo){
              this.tbody_isreport.push({
                state: data.state,
                reportid: data.reportid,
                executivesummary: data.executivesummary,
                fullreport: data.fullreport,
                filesampling: data.filesampling,
                coa: data.coa,
                coc: data.coc,
                casetitle: data.casetitle,
                casestartdate_ui: this.useConvertDisplayDatetime(data.casestartdate),
                caseenddate_ui: this.useConvertDisplayDatetime(data.caseenddate),
                samplingpoint: data.pointinfo[i].samplingpoint,
                pointriver: data.pointinfo[i].pointriver,
                locationdescription: data.pointinfo[i].locationdescription,
                remarks: data.remarks,
              })
            }

            // data["casestartdate_ui"] = this.useConvertDisplayDatetime(data["casestartdate"]);
            // data["caseenddate_ui"] = this.useConvertDisplayDatetime(data["caseenddate"]);
            
            this.dataSelectedReportCaseID = data
            // this.tbody_isreport = data

            console.log(data);

            this.loadingTable = false;
            
        })
        .catch(error => {
            console.log(error);
            this.loadingTable = false;
        })

      },

      openAttachFile(key){
        window.open(this.tbody_isreport[0][key], '_blank');
      }

      
    },

}
</script>



<style lang="scss">

    @import '~scss/main';

     /* Section Path File/Modul */
    .pathModul{
      padding-bottom: 0px;
    }

    .pathModul > li:nth-child(3) > a{
      color: black !important;
    }

    .table_section{
      max-width: 80% !important;
    }

    .standard_table > .v-data-table__wrapper> table > thead > tr > th{
      /* color: white !important;
      background-color: #A989C4;
      border: 1px solid #2C3E50;
      border-collapse: collapse;
      text-align: center !important;
      font-size: 14px !important; */
      background-image: linear-gradient(to bottom, #1476bf, #0aafdd) !important;
      color: white !important;
      font-weight: bold !important;
      border: 0.1px solid white !important;
    }
    
    .standard_table > .v-data-table__wrapper> table > tbody > tr > td{
      border-collapse: collapse;
      border: 1px solid #D5D8DC;
    }

    
  .level_panel_selection{
    line-height: normal;
  }

  .column_pdf{
    text-align: center;cursor: pointer;
  }

  .wrapper {
    height: calc(100vh - 212px);
    display: flex;
  }


  .container {
    flex: 1 1 auto;
    overflow: auto;
  }

  .button {
    border: 1px solid #6696A8;
    border-radius: 6px;
    color: #FFF;
    padding: 10px 12px;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    outline: none;
    margin: 12px 8px;
    cursor: pointer;
  }

  .subTitle{
    text-align: center !important;
    background: white;
  }

  .cust-is-simple-table > .v-data-table__wrapper > table > tbody > tr:hover{
    background-color: transparent !important;
  }

</style>